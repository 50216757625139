import { useNavigate, useParams } from "react-router-dom"
import PageLayout from "../../components/PageLayout"
import { Box, Typography, useTheme } from "@mui/material"
import useScrollTop from "../../../../hooks/useScrollTop"
import Articleblock from "./components/ArticleBlock"
import NavigationBlock from "../../components/NavigationBlock"
import Bell from '../../../../assets/images/dashboard/bell.webp';
import { AppRoutes } from "../../../../utils/constants"
import { articles } from "./Articles.constants"
import { useLayoutEffect } from "react"


const Articles = () => {
    const { slug } = useParams()
    const navigate = useNavigate()
    useScrollTop();
    const theme = useTheme();

    const content = articles[slug ?? '']

    useLayoutEffect(() => {
        if (!slug || !articles[slug]) {
            navigate(AppRoutes.DASHBOARD)
        }

    }, [slug])

    if (!content) return null

    return <PageLayout title={content.title}>
        <Box
            sx={{
                width: '100%',
                padding: '30px 20px',
                '& .bg': {
                    width: '120px',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }
            }}
        >
            <Typography
                sx={{
                    color: theme.palette.text.primary,
                    fontFamily: 'Avenir Next',
                    fontSize: '20px',
                    fontWeight: 600,
                    display: 'inline-block',
                    maxWidth: '230px',
                    lineHeight: '135%',
                    marginBottom: '30px'
                }}
            >
                {content.description}
            </Typography>
            <img className="bg" src={content.bg} />
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    gap: '20px',
                }}
            >
                {
                    content.blocks.map((item, index) => <Articleblock
                        part={index+1}
                        title={item.title}
                        description={item.description}
                        path={item.path}
                    />)
                }
            </Box>
            <Box
                sx={{
                    height: '1px',
                    background: theme.palette.common.lightGrey,
                    margin: '40px 0',
                }}
            />
            <NavigationBlock
                image={Bell}
                title="Keep up to date with the latest news in the field of promotion of Instagram accounts and learn new things"
                buttonText="Read news"
                path={AppRoutes.NEWS}
            />
        </Box>
    </PageLayout>
}

export default Articles