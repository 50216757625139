import {
  SubscribeRecurlySuccess,
  SubscribeRecurlyError,
} from '../services/requests';

export const isSuccessfullySubscriptionResponse = (
  response: any
): response is SubscribeRecurlySuccess => {
  return typeof response.id === 'string';
};

export const isErrorSubscriptionResponse = (
  response: any
): response is SubscribeRecurlyError => {
  return typeof response.error === 'string';
};
