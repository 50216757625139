import styled from '@emotion/styled';
import Button from '../Button';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 38, 49, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 999999;
    touch-action: pan-x pan-y;

`;

export const Container = styled.div`
  background: white;
  border-radius: 20px 20px 0 0;
  padding: 30px 20px 20px;
  max-width: 430px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Badge = styled.div`
  width: 96px;
  height: 96px;
`;

export const Heading = styled.h2`
  margin: 15px 0 0;
  font-family: Avenir Next;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  color: #232631;
`;

export const Description = styled.p`
  margin: 5px 0 0;
  font-family: Avenir Next;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #8c8e96;
  white-space: pre-wrap;

  b {
    font-weight: 600;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: left;
`;

export const InputLabel = styled.label`
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #232631;
`;

export const Input = styled.input<{ invalid: boolean }>`
  margin-top: 5px;
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  padding: 14px 14px 14px 49px;
  color: #232631;
  outline: none;
  border-color: ${(props) => (props.invalid ? '#e53e3e' : '#2326311a')};
  touch-action: manipulation;

  &::placeholder {
    font-weight: 400;
    color: #8c8e96;
  }
`;

export const Error = styled.p`
  margin: 5px 0 0 0;
  font-family: Avenir Next;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #e53e3e;
`;

export const InputWrapper = styled.div`
  position: relative;

  img {
    display: block;
    position: absolute;
    top: 54%;
    transform: translateY(-50%);
    left: 15px;
  }
`;

export const SubmitButton = styled(Button)`
  position: relative;
  margin-top: 20px;
`;

export const LoaderWrapper = styled.div`
  position: relative;
`;

export const Loader = styled.div`
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  background: linear-gradient(0deg, rgba(255, 61, 0, 0.2) 33%, #ff3d00 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 146px;
    height: 146px;
    border-radius: 50%;
    background: #f5f2ed;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Progress = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Avenir Next;
  font-size: 28px;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
  color: #282624;
  z-index: 2;
`;
