import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowIcon from '../../../../../../assets/svg/arrow-left.svg';
import Button from "../../../../components/Button";
import PageLayout from "../../../../components/PageLayout";
import { articles } from "../../Articles.constants";
import Section from "./components/Section";
import { useLayoutEffect } from "react";
import { AppRoutes } from "../../../../../../utils/constants";
import useScrollTop from "../../../../../../hooks/useScrollTop";


const Article = () => {
    const { slug, subSlug } = useParams()
    const theme = useTheme();
    const navigate = useNavigate()
    useScrollTop();

    const textSx = {
        color: theme.palette.text.primary,
        fontFamily: 'Avenir Next',
        fontSize: '16px',
        fontWeight: 400,
        display: 'inline-block',
        lineHeight: '135%',
        '& span': {
            fontWeight: 600,
        }
    }

    useLayoutEffect(() => {
        if (!slug || !articles[slug]) {
            navigate(AppRoutes.DASHBOARD)
        }else if(!subSlug || !articles[slug].articles[subSlug]){
            navigate(`${AppRoutes.ARTICLES}/${slug}`)
        }

    }, [slug,subSlug])

    const handleClick = ()=>{
        navigate(`${AppRoutes.ARTICLES}/${slug}`)
    }

    const block = articles[slug ?? '']
    const article = block.articles[subSlug ?? '']


    if (!block || !article) return null

    return <PageLayout title={block.title} backPath={`${AppRoutes.ARTICLES}/${slug}`}>
        <Box
            sx={{
                width: '100%',
                padding: '30px 20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
            }}
        >

            <Typography
                sx={{
                    ...textSx,
                    fontSize: '24px',
                    fontWeight: 600,
                }}
            >
                {article.title}
            </Typography>
            {
                !!article.overview && <Typography
                    sx={textSx}
                >
                    {article.overview}
                </Typography>
            }

            <Box
                component={"ol"}
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                }}
            >
                {
                    article.sections.map((item, index) => <Section key={item.heading} section={item} />)
                }

            </Box>
            {
                !!article.conclusion && <Typography
                    sx={textSx}
                >
                    {article.conclusion}
                </Typography>
            }
            <Button
                sx={{
                    position: 'relative',
                    margin: '20px 0 0',
                    width: '100%',
                    padding: '19px',
                    background: theme.palette.grey['300'],
                    fontFamily: 'Avenir Next',
                    fontSize: '20px',
                    fontWeight: '600',
                    lineHeight: '27.32px',
                    textAlign: 'center',
                    borderRadius: '12px',
                    textTransform: 'initial',
                    color: theme.palette.text.primary,
                    '&:hover': {
                        background: theme.palette.grey['300'],
                    },
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: '50%',
                        left: '15px',
                        transform: 'translateY(-50%)',
                        display: 'block',
                        width: '24px',
                        height: '24px',
                        backgroundImage: `url(${ArrowIcon})`,
                    },
                }}
                onClick={handleClick}
            >
                To all articles
            </Button>
        </Box>
    </PageLayout>
}
export default Article