import { useAppState } from '../providers/AppProvider.hooks';
import React, { useState } from 'react';
import { createSubscription, fetchInstagramUser } from '../services/requests';
import UsernamePopover from './UsernamePopover';
import { isSuccessfullySubscriptionResponse } from '../utils/subscription';

const BuyPdfReportWithUsernamePopOver = ({
  onClose,
  planId,
}: {
  onClose: () => void;
  planId: string;
}) => {
  const {
    firebaseUserCredential,
    checkUserSubscription,
    handleSendPDFReportData,
    subscriptionId,
  } = useAppState();

  const [error, setError] = useState('');

  const [screen, setScreen] = useState<'form' | 'loading' | 'success'>('form');

  const onSubmit = async (username: string) => {
    if (firebaseUserCredential && firebaseUserCredential.email) {
      const handleError = async (err: any) => {
        try {
          const error = err as Response;

          const response = (await error.json()) as {
            message?: string;
            error?: string;
          };

          const message =
            response.message || response.error || 'Something went wrong';

          setError(message);
        } catch (err: any) {
          console.error(err);
          setError(err?.message || 'Something went wrong');
        } finally {
          setScreen('form');
        }
      };

      try {
        setScreen('loading');

        await fetchInstagramUser({
          username: username,
          subscriptionId: subscriptionId,
          uid: firebaseUserCredential.uid ?? null,
          environment: process.env.REACT_APP_ENVIRONMENT ?? null,
        });

        const subResponse = await createSubscription({
          plan_id: planId,
          email: firebaseUserCredential.email,
          firebase_id: firebaseUserCredential.uid,
        });

        if (isSuccessfullySubscriptionResponse(subResponse)) {
          await handleSendPDFReportData(username);
          await checkUserSubscription(firebaseUserCredential.uid);
          setScreen('success');
        } else {
          await handleError(subResponse);
        }
      } catch (err) {
        await handleError(err);
        console.error(err);
      }
    }
  };

  return (
    <UsernamePopover
      duration={120}
      error={error}
      screen={screen}
      onSubmit={onSubmit}
      onSuccess={onClose}
      onClose={onClose}
    />
  );
};

export default BuyPdfReportWithUsernamePopOver;
