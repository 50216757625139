import { Box } from '@mui/material';
import { AppRoutes } from '../../../../utils/constants';
import NavigationCard from './components/NavigationCard';
// import AnalyticsImage from '../../../../assets/images/dashboard/navigation-card/analytics-bg-card.webp';
import InsightsImageOp from '../../../../assets/images/dashboard/navigation-card/insights--op-bg-card.webp';
import InsightsImage from '../../../../assets/images/dashboard/navigation-card/insights-bg-card.webp';
import InstProImage from '../../../../assets/images/dashboard/navigation-card/instagram-pro-bg-card.webp';
import NewsImage from '../../../../assets/images/dashboard/navigation-card/news-bg-card.webp';
import AdmirersImage from '../../../../assets/images/dashboard/navigation-card/admirers.webp';
import StalkersImage from '../../../../assets/images/dashboard/navigation-card/stalkers.webp';
import PasswordImage from '../../../../assets/images/dashboard/navigation-card/password-bg-card.webp';
// import background from '../../../../assets/svg/dashboard_mobile_background.svg';

import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import PdfCoursePurchaseModal from '../../../common/PdfCoursePurchaseModal';
import useEstimatedInsightsResponse from '../../hooks/useEstimatedInsightsResponse';

function Main() {
  const [timeLeft, onClickByInsightsCard] = useEstimatedInsightsResponse();
  const {
    isHasPdfCourseSubscription,
    setPdfCoursePurchaseModalOpen,
    isPdfCoursePurchaseModalOpen,
  } = useAppState();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 24px 56px',
          gap: '15px',
          paddingTop: '111px',
          background: '#DAE2FF',
        }}
      >
        {/* <NavigationCard
          title="Analytics"
          description="Analysis of your account metrics and statistics"
          onClick={() => navigate(AppRoutes.ANALYTICS)}
          image={AnalyticsImage}
        /> */}
        <NavigationCard
          title="Tips & Insights"
          description="Find out how to improve your account metrics"
          onClick={onClickByInsightsCard}
          timeLeft={null}
          image={null ? InsightsImageOp : InsightsImage}
        />
        <NavigationCard
          title="Secret Admirers"
          description="Know who’s watching your profile without following you"
          onClick={() => navigate(`${AppRoutes.ARTICLES}/secret-admirers`)}
          timeLeft={null}
          image={AdmirersImage}
        />
        <NavigationCard
          title="Detect Stalkers"
          description="Learn how to identify, address, and prevent stalking or data breaches"
          onClick={() => navigate(`${AppRoutes.ARTICLES}/detect-stalkers`)}
          timeLeft={null}
          image={StalkersImage}
        />
        <NavigationCard
          title="Password safety"
          description="Find out if all your passwords are securely protected"
          onClick={() => navigate(AppRoutes.PASSWORD_SAFETY)}
          image={PasswordImage}
        />
        <NavigationCard
          title="News"
          description="Stay up to date with current and popular trends"
          onClick={() => navigate(AppRoutes.NEWS)}
          image={NewsImage}
        />
        <NavigationCard
          label="10-lessons course"
          title="Instagram PRO"
          description="Strategies to boost engagement on your Instagram profile"
          onClick={() => {
            if (isHasPdfCourseSubscription) {
              navigate(AppRoutes.PDF_COURSE);
            } else {
              setPdfCoursePurchaseModalOpen(true);
            }
          }}
          image={InstProImage}
        />
      </Box>
      <PdfCoursePurchaseModal
        isModalOpen={isPdfCoursePurchaseModalOpen}
        setModalOpen={setPdfCoursePurchaseModalOpen}
      ></PdfCoursePurchaseModal>
    </>
  );
}

export default Main;
