import { Box, Typography, useTheme } from "@mui/material"
import BaseButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";

const Articleblock = (
    {
        part,
        title,
        description,
        path
    }: {
        part: number,
        title: string,
        description: string,
        path: string
    }) => {
    const theme = useTheme();
    const navigate = useNavigate()

    const handleCLick = ()=>{
        navigate(path)
    }

    return <Box
        sx={{
            with: '100%',
            padding: '20px',
            borderRadius: '24px',
            border: `1px solid ${theme.palette.common.lightGrey}`,
            '& button':{
                padding:'15px',
                fontSize:'16px'
            }
        }}
    >
        <Box
            sx={{
                background: theme.palette.common.lightBlue10,
                width: 'max-content',
                padding: '5px 15px',
                borderRadius: '23px',
                fontFamily: 'Avenir Next',
                fontSize: '14px',
                fontWeight: '500',
                color: theme.palette.common.blue,
            }}
        >
            Part&nbsp;{part}
        </Box>
        <Typography
            sx={{
                margin: '5px 0',
                color: theme.palette.text.primary,
                fontFamily: 'Avenir Next',
                fontSize: '20px',
                fontWeight: 600,
            }}
        >
            {title}
        </Typography>
        <Typography
            sx={{
                margin: '0 0 20px',
                color: theme.palette.text.secondary,
                fontFamily: 'Avenir Next',
                fontSize: '14px',
                fontWeight: 400,
            }}
        >
            {description}
        </Typography>

        <BaseButton
            fullWidth
            withIcon
            onClick={handleCLick}
        >
            Read article
        </BaseButton>

    </Box>
}
export default Articleblock