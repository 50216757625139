import PageLayout from '../../components/PageLayout';
import { Box, Typography, useTheme } from '@mui/material';
import BuilbImg from '../../../../assets/images/dashboard/bell.webp';
import ShieldImg from '../../../../assets/images/dashboard/passwordSafety/shield.webp';
import WarningImg from '../../../../assets/images/dashboard/passwordSafety/warning.webp';
import svg1 from '../../../../assets/images/dashboard/passwordSafety/svg1.svg';
import svg2 from '../../../../assets/images/dashboard/passwordSafety/svg2.svg';
import svg3 from '../../../../assets/images/dashboard/passwordSafety/svg3.svg';
import { useEffect, useState } from 'react';
import Divider from '../../components/Divider';
import NavigationBlock from '../../components/NavigationBlock';
import { AppRoutes } from '../../../../utils/constants';
import useScrollTop from '../../../../hooks/useScrollTop';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import { fetchBreachedSites, trackEvent } from '../../../../services/requests';

export default function PasswordSafety() {
  const {
    userBreachedSites,
    setUserBreachedSites,
    isBreachedSitesInfoFetched,
    setBreachedSitesInfoFetched,
    firebaseUserCredential,
  } = useAppState();

  useScrollTop();

  const theme = useTheme();

  const sectionTitleSx = {
    color: theme.palette.text.primary,
    fontFamily: 'Avenir Next',
    fontSize: '24px',
    fontWeight: 600,
    margin: '40px 0 15px',
    lineHeight: '32.78px',
    textAlign: 'center',
    span: {
      color: '#F63C47',
    },
  };

  const sectionSubTitleSx = {
    color: theme.palette.text.primary,
    fontFamily: 'Avenir Next',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: 'normal',
  };

  const badgeTitleSx = {
    color: '#fff',
    fontFamily: 'Avenir Next',
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    borderRadius: '16px',
    padding: '8px 12px',
    marginBottom: '20px',
  };

  const userEmail = firebaseUserCredential?.email;

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!isBreachedSitesInfoFetched) {
      if (userEmail) {
        fetchBreachedSites(userEmail)
          .then((res) => {
            setUserBreachedSites(res);
            setBreachedSitesInfoFetched(true);

            trackEvent('Pwned success response', {
              breachedSitesCount: res.length,
              host: window.location.host,
              status: 200,
              project: 'metricgurus',
              email: userEmail,
            }).catch(console.error);
          })
          .catch((err) => {
            if (err.status === 404) {
              // user not found, ok
              trackEvent('Pwned success response', {
                breachedSitesCount: 0,
                host: window.location.host,
                status: err.status,
                project: 'metricgurus',
                email: userEmail,
              }).catch(console.error);

              setBreachedSitesInfoFetched(true);
            } else if (err.status === 429) {
              setError('Please try again later.');
              setBreachedSitesInfoFetched(false);
              trackEvent('Pwned rate limit error', {
                error: 'Too many requests — the rate limit has been exceeded',
                status: err.status,
                host: window.location.host,
                project: 'metricgurus',
                email: userEmail,
              }).catch(console.error);
            } else {
              setError('Something went wrong. Please try again later.');
              setBreachedSitesInfoFetched(false);
              trackEvent('Pwned error', {
                error: JSON.stringify(err),
                status: err.status,
                host: window.location.host,
                project: 'metricgurus',
                email: userEmail,
              }).catch(console.error);
            }
          });
      }
    }
  }, [userBreachedSites, setUserBreachedSites, setBreachedSitesInfoFetched, userEmail, isBreachedSitesInfoFetched]);

  return (
    <PageLayout title="Password safety">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '30px 20px 0',
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {!(userBreachedSites && userBreachedSites?.length > 0) ? (
            <Box
              sx={{
                display: error ? 'none' : 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                img: {
                  maxWidth: '100%',
                },
                visibility: isBreachedSitesInfoFetched ? undefined : 'hidden',
              }}
            >
              <Box
                sx={{
                  background: '#56AC14',
                  ...badgeTitleSx,
                }}
              >
                No breached accounts
              </Box>
              <img className="main-img" src={ShieldImg} alt={'No breached accounts'} />
              <Typography sx={sectionTitleSx}>Good news!</Typography>
              <Typography sx={sectionSubTitleSx}>Your email address is not associate with any data breaches</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: error ? 'none' : 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                img: {
                  maxWidth: '100%',
                },
                visibility: isBreachedSitesInfoFetched ? undefined : 'hidden',
              }}
            >
              <Box
                sx={{
                  background: '#F63C47',
                  ...badgeTitleSx,
                }}
              >
                EMAIL COMPROMISED!
              </Box>
              <img className="main-img" src={WarningImg} alt={'EMAIL COMPROMISED!'} />
              <Typography sx={sectionTitleSx}>
                Your email "{userEmail}" is compromised on <span>{userBreachedSites?.length ?? 0} breached sites</span>
              </Typography>
            </Box>
          )}
          {!isBreachedSitesInfoFetched && !error && (
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              <span className="appLoader"></span>
            </Box>
          )}
          {error && <Typography sx={sectionTitleSx}>{error}</Typography>}
        </Box>

        {userBreachedSites && userBreachedSites.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {userBreachedSites.map((el, index) => (
              <Box
                key={index}
                sx={{
                  border: '1px solid #F63C47',
                  borderRadius: '24px',
                  padding: '20px',
                  '& .divider': {
                    height: '1px',
                    background: '#232631',
                    opacity: 0.1,
                    margin: '15px 0',
                  },
                  '& .wrapper': {
                    display: 'flex',
                    gap: '15px',
                    div: {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '2px',
                    },
                    '& .title': {
                      color: theme.palette.text.primary,
                      fontFamily: 'Avenir Next',
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                    },
                    '& .subTitle': {
                      color: theme.palette.text.secondary,
                      fontFamily: 'Avenir Next',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: 'normal',
                    },
                  },
                }}
              >
                <Box className="wrapper">
                  <img src={svg1} alt="decor"></img>
                  <Box>
                    <Typography className="title">{el.Name}</Typography>
                    <Typography className="subTitle">{el.Domain}</Typography>
                  </Box>
                </Box>
                <Box className="divider" />
                <Box className="wrapper">
                  <img src={svg2} alt="decor"></img>
                  <Box>
                    <Typography className="subTitle">Breach Date</Typography>
                    <Typography className="title">{el.BreachDate}</Typography>
                  </Box>
                </Box>
                <Box className="divider" />
                <Box className="wrapper">
                  <img src={svg3} alt="decor"></img>
                  <Box>
                    <Typography className="subTitle">Compromised data</Typography>
                    <Typography className="title">
                      {el.DataClasses.map((el, i, arr) => {
                        if (i < arr.length - 1) {
                          return `${el}, `;
                        }
                        return el;
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        <Divider margin="40px 0" />

        <NavigationBlock
          title="Keep up to date with the latest news in the field of promotion of Instagram accounts and learn new things"
          path={AppRoutes.NEWS}
          image={BuilbImg}
          buttonText="Read news"
        />
      </Box>
    </PageLayout>
  );
}
