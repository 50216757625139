import { Box, Typography, useTheme } from "@mui/material"
import { Section as SectionType } from "../../../Articles.types";

const Section = ({ section }: { section: SectionType }) => {
    const theme = useTheme();

    const textSx = {
        color: theme.palette.text.primary,
        fontFamily: 'Avenir Next',
        fontSize: '16px',
        fontWeight: 400,
        display: 'inline-block',
        lineHeight: '135%',
        '& span': {
            fontWeight: 600,
        }
    }

    return <Box
        sx={{
            padding: '30px 20px',
            background: theme.palette.grey[300],
            borderRadius: '16px',
            display:'flex',
            flexDirection:'column',
            gap:'15px',
        }}
    >
        <Box
            sx={{
                ...textSx,
                fontSize: '20px',
                fontWeight: 600,
                paddingLeft: '20px',
                listStyle: 'decimal'
            }}
        >
            <li>
                {section.heading}
            </li>

        </Box>
        <Typography
            sx={textSx}
        >
            {section.description}
        </Typography>
        <Box
            component={"ul"}
            sx={{
                ...textSx,
                display:'flex',
                flexDirection:'column',
                gap:'15px',
                paddingLeft: '8px',
                '& li': {
                    paddingLeft: '15px', // Увеличиваем отступ между маркером и текстом
                },
                '& li::marker': {
                    color: '#56AC14',
                    fontWeight: 'bold',
                    content: '"•"',
                }
            }}
        >
            {
                section.details.map((item) => <li>
                    <span>{item.tip}</span> {item.description}
                </li>)
            }
        </Box>
    </Box>
}
export default Section