import React from 'react';

import Spinner, { Overlay } from '../Spinner';

import * as S from './Button.style';

type CommonProps = {
  as?: React.ElementType;
  loading?: boolean;
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CommonProps;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { type = 'button', children, loading = false, ...rest }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <S.StyledButton
        ref={ref}
        type={type}
        {...rest}
        children={
          loading ? (
            <>
              {children}
              <Overlay>
                <Spinner absolute />
              </Overlay>
            </>
          ) : (
            children
          )
        }
      />
    );
  }
);

export default Button;
