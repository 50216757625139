import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ArrowRight from '../../../../../../assets/svg/arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import { Nullable } from '../../../../../../models/common';
import hourArrow from '../../../../../../assets/svg/clock/gray/group_3.svg';
import clockBase from '../../../../../../assets/svg/clock/gray/group_1.svg';
import minuteArrow from '../../../../../../assets/svg/clock/gray/group_2.svg';

const TimeLeft = ({ value }: { value: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '20px',
        minWidth: '84px',
        height: '68px',
        zIndex: 2,
        background: 'white',
        borderRadius: '12px',
      }}
    >
      <Box
        sx={{
          minWidth: '24px',
          minHeight: '24.5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          '& img': {
            position: 'absolute',
          },
        }}
      >
        <Box
          className="hourArrow"
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
          }}
        >
          <img
            style={{
              transform: 'translateY(-2.5px)',
            }}
            src={hourArrow}
            alt="clock"
          ></img>
        </Box>
        <img src={clockBase} alt="clock"></img>
        <Box
          className="minuteArrow"
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              transform: 'translateY(-3.5px)',
            }}
            src={minuteArrow}
            alt="clock"
          ></img>
        </Box>
      </Box>
      <Typography
        sx={{
          margin: '4px 0 0 0',
          fontFamily: 'Avenir Next',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '19.12px',
          textAlign: 'center',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default function NavigationCard({
  title,
  description,
  image,
  timeLeft,
  onClick,
  label,
}: {
  title: string;
  description: string;
  image: string;
  timeLeft?: Nullable<string>;
  onClick: () => void;
  label?: string;
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      onClick={onClick}
      sx={{
        WebkitTapHighlightColor: 'transparent',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 30px',
        gap: '5px',
        borderRadius: '16px',
        overflow: 'hidden',
        background: theme.palette.grey['300'],
        cursor: 'pointer',
        '& > .background-image': {
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          objectFit: 'cover',
        },
      }}
    >
      {timeLeft && <TimeLeft value={timeLeft} />}

      {label && (
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '10px',
            fontWeight: 700,
            lineHeight: 'normal',
            display: 'flex',
            padding: '8px 12px',
            color: '#fff',
            textTransform: 'uppercase',
            width: 'max-content',
            borderRadius: '16px',
            background: 'linear-gradient(270deg, #FF9A84 0%, #EF5DCE 50%, #2B42DB 100%)',
          }}
        >
          {label}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          color: theme.palette.text.primary,
          position:'relative',
          zIndex:1,
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
          }}
        >
          {title}
        </Typography>
        <img src={ArrowRight} alt="icon" />
      </Box>
      <Typography
        sx={{
          maxWidth: '173px',
          fontFamily: 'Avenir Next',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '22px',
          color: theme.palette.text.secondary,
          position:'relative',
          zIndex:1,
        }}
      >
        {description}
      </Typography>

      <img className="background-image" src={image} alt="bg-image" />
    </Box>
  );
}
