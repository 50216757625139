import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import background from '../../../../assets/svg/dashboard_mobile_background.svg';
import menu from '../../../../assets/svg/menu_icons/menu.svg';
import close from '../../../../assets/svg/mobile_close.svg';
import followers from '../../../../assets/svg/followers.svg';
import following from '../../../../assets/svg/following.svg';
import posts from '../../../../assets/svg/posts.svg';
import my_plan from '../../../../assets/svg/menu_icons/my_plan.svg';
import detect_stalkers from '../../../../assets/svg/menu_icons/detect_stalkers.svg';
import password_safety from '../../../../assets/svg/menu_icons/password_safety.svg';
import heart_hand from '../../../../assets/svg/menu_icons/heart-hand.svg';
import tips_insights from '../../../../assets/svg/menu_icons/tips_insights.svg';
import instagram_svg from '../../../../assets/svg/menu_icons/instagram.svg';
import terms_svg from '../../../../assets/svg/menu_icons/terms.svg';
import privacy_svg from '../../../../assets/svg/menu_icons/privacy.svg';
import log_out_svg from '../../../../assets/svg/menu_icons/log_out.svg';

import analyticsSvg from '../../../../assets/svg/menu_icons/heart-hand.svg';
import insightsSvg from '../../../../assets/svg/menu_icons/file-heart.svg';
import newsSvg from '../../../../assets/svg/menu_icons/bell.svg';

import { useAppState } from '../../../../providers/AppProvider.hooks';
import { numberWithCommas } from '../../../../utils/numbers';
import { AppRoutes, STORAGE_USERNAME_KEY } from '../../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { ManageSubscriptions } from '../../pages';

function HeroMobile() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isManageSubscriptionsOpen, setManageSubscriptionsOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const { setLogOutModalOpen, instagramUserData, setInstagramUserData, setShowUpdateModal } = useAppState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const navigate = useNavigate();

  const handleSwitchInstagramAccount = () => {
    window.localStorage.removeItem(STORAGE_USERNAME_KEY);
    setInstagramUserData(null);
    setShowUpdateModal(true);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isMenuOpen]);

  function handleClickByMenuItem(path: AppRoutes) {
    document.body.style.overflowY = 'unset';
    navigate(path);
  }

  return (
    <Box
      sx={{
        width: '100%',
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'repeat-x',
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          zIndex: 10,
          top: 0,
          width: '100%',
          maxWidth: '430px',
          height: '78px',
          transition: isMenuOpen ? undefined : 'all 0.2s ease',
          background: isMenuOpen || offset > 0 ? '#fff' : 'transparent',
          borderBottom: '1px solid #23263110',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 20px',
            height: '78px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: '9px',
              alignItems: 'center',
            }}
          >
            <img width={48} height={48} src={'/logo128.webp'} alt="logo"></img>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,

                  fontSize: '15px',
                  color: '#232631',
                  lineHeight: 'normal',
                }}
              >
                MetricMaster
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,

                  fontSize: '10.5px',
                  color: '#8C8E96',
                  lineHeight: 'normal',
                }}
              >
                Profile Analyzer
              </Typography>
            </Box>
          </Box>
          <img
            onClick={() => {
              setMenuOpen((prev) => !prev);
            }}
            src={isMenuOpen ? close : menu}
            alt="menu"
          ></img>
        </Box>
      </Box>
      {isMenuOpen && (
        <Box
          sx={{
            maxWidth: '430px',
            width: '100%',
            backgroundColor: 'rgba(35, 38, 49, 0.6)',
            position: 'fixed',
            top: 0,
            bottom: 0,
            zIndex: 9,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              maxWidth: '430px',
              width: '100%',
              background: '#fff',
              marginTop: '77px',
              zIndex: 9,
            }}
          >
            <Box
              sx={{
                width: '100%',
                paddingTop: '24px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                overflow: 'auto',
                '& .menu_item': {
                  WebkitTapHighlightColor: 'transparent',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  height: '48px',
                  margin: ' 0 8px',
                  cursor: 'pointer',
                  padding: '0 16px',
                  transition: 'all 0.3s ease',
                  '& p': {
                    fontWeight: 400,
                    userSelect: 'none',
                    fontSize: '16px',
                    color: '#232631',
                    lineHeight: 'normal',
                  },
                },
              }}
            >
              <Box className="menu_item" onClick={() => handleClickByMenuItem(AppRoutes.INSIGHTS)}>
                <img src={tips_insights} alt="Insights"></img>
                <Typography>Tips & Insights</Typography>
              </Box>

              <Box
                className="menu_item"
                onClick={() => handleClickByMenuItem(`${AppRoutes.ARTICLES}/secret-admirers` as AppRoutes)}
              >
                <img src={heart_hand} alt="Secret Admirers"></img>
                <Typography>Secret Admirers</Typography>
              </Box>
              <Box
                className="menu_item"
                onClick={() => handleClickByMenuItem(`${AppRoutes.ARTICLES}/detect-stalkers` as AppRoutes)}
              >
                <img src={detect_stalkers} alt="Detect Stalkers"></img>
                <Typography>Detect Stalkers</Typography>
              </Box>
              <Box className="menu_item" onClick={() => handleClickByMenuItem(AppRoutes.PASSWORD_SAFETY)}>
                <img src={password_safety} alt="Passwords safety"></img>
                <Typography>Password safety</Typography>
              </Box>
              <Box className="menu_item" onClick={() => handleClickByMenuItem(AppRoutes.NEWS)}>
                <img src={newsSvg} alt="News"></img>
                <Typography>News</Typography>
              </Box>
              <Box
                sx={{
                  height: '1px',
                  background: '#232631',
                  opacity: 0.1,
                  margin: '24px 20px',
                }}
              />
              {/* <Box
                className="menu_item"
                onClick={() => {
                  window.open('mailto:' + APP_CONSTANTS.SUPPORT, '_blank');
                }}
              >
                <img src={support_svg} alt="support"></img>
                <Typography>Support</Typography>
              </Box> */}

              <Box
                className="menu_item"
                onClick={() => {
                  window.open(AppRoutes.TERMS, '_blank');
                }}
              >
                <img src={terms_svg} alt="terms"></img>
                <Typography>Terms of Service</Typography>
              </Box>
              <Box
                className="menu_item"
                onClick={() => {
                  window.open(AppRoutes.PRIVACY, '_blank');
                }}
              >
                <img src={privacy_svg} alt="privacy"></img>
                <Typography>Privacy Policy</Typography>
              </Box>
              <Box
                className="menu_item"
                onClick={() => {
                  setManageSubscriptionsOpen(true);
                }}
              >
                <img src={my_plan} alt="my_plan"></img>
                <Typography>My Plan</Typography>
              </Box>
              <Box
                sx={{
                  height: '1px',
                  background: '#232631',
                  opacity: 0.1,
                  margin: '24px 20px 0',
                }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                padding: '24px 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                '& .menu_item': {
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  height: '48px',
                  margin: ' 0 8px',
                  cursor: 'pointer',
                  padding: '0 16px',
                  transition: 'all 0.3s ease',
                  '& p': {
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#FF435A',
                    lineHeight: 'normal',
                  },
                },
              }}
            >
              <Box
                className="menu_item"
                onClick={() => {
                  setLogOutModalOpen(true);
                }}
              >
                <img src={log_out_svg} alt="log out"></img>
                <Typography>Log out</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* <Box
        sx={{
          marginTop: '111px',
        }}
      >
        <Box
          sx={{
            width: '88px',
            minWidth: '88px',
            minHeight: '88px',
            borderRadius: '50%',
            background: '#fff',
            margin: 'auto',
            backgroundImage: `url("https://cors.huracanapps.com/${instagramUserData?.profilePicUrl}")`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        ></Box>
        <Typography
          sx={{
            fontWeight: 600,

            fontSize: '20px',
            color: '#232631',
            lineHeight: 'normal',
            textAlign: 'center',
            marginTop: '12px',
          }}
        >
          {instagramUserData?.fullName}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            color: '#8C8E96',
            lineHeight: 'normal',
            marginTop: '2px',
            textAlign: 'center',
          }}
        >
          @{instagramUserData?.username}
        </Typography>
        <Box
          sx={{
            width: '100%',
            paddingBottom: '32px',
            paddingTop: '24px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '106px',
            }}
          >
            <Box
              sx={{
                width: '56px',
                minWidth: '56px',
                minHeight: '56px',
                background: 'rgba(35, 38, 49, 0.05)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={followers} alt="followers"></img>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,

                fontSize: '14px',
                color: '#8C8E96',
                lineHeight: 'normal',
                textAlign: 'center',
                marginTop: '8px',
                marginBottom: '2px',
              }}
            >
              Followers
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,

                fontSize: '16px',
                color: '#232631',
                lineHeight: 'normal',
                textAlign: 'center',
              }}
            >
              {numberWithCommas(instagramUserData?.followersCount)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '106px',
            }}
          >
            <Box
              sx={{
                width: '56px',
                minWidth: '56px',
                minHeight: '56px',
                background: 'rgba(35, 38, 49, 0.05)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={posts} alt="posts"></img>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,

                fontSize: '14px',
                color: '#8C8E96',
                lineHeight: 'normal',
                textAlign: 'center',
                marginTop: '8px',
                marginBottom: '2px',
              }}
            >
              Posts
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,

                fontSize: '16px',
                color: '#232631',
                lineHeight: 'normal',
                textAlign: 'center',
              }}
            >
              {numberWithCommas(instagramUserData?.postsCount)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '106px',
            }}
          >
            <Box
              sx={{
                width: '56px',
                minWidth: '56px',
                minHeight: '56px',
                background: 'rgba(35, 38, 49, 0.05)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={following} alt="following"></img>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,

                fontSize: '14px',
                color: '#8C8E96',
                lineHeight: 'normal',
                textAlign: 'center',
                marginTop: '8px',
                marginBottom: '2px',
              }}
            >
              Following
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,

                fontSize: '16px',
                color: '#232631',
                lineHeight: 'normal',
                textAlign: 'center',
              }}
            >
              {numberWithCommas(instagramUserData?.followsCount)}
            </Typography>
          </Box>
        </Box>
      </Box> */}
      {isManageSubscriptionsOpen && (
        <ManageSubscriptions
          setManageSubscriptionsOpen={setManageSubscriptionsOpen}
          handleClickByMenuItem={handleClickByMenuItem}
          setMenuOpen={setMenuOpen}
        />
      )}
    </Box>
  );
}

export default HeroMobile;
