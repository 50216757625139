import { ConstantMap, FetchStatus } from '../models/common';

export const APP_CONSTANTS = {
  SUPPORT: 'support@followersreport.com',
  SUPPORT_NEW: 'support@boostgram.live',
  MAKE_ACCOUNT_PUBLIC_URL: 'https://help.instagram.com/284802804971822/?helpref=hc_fnav',
} as const;

export const PDF_COURSE_PRICE = {
  sum: 28.99,
  fullPrice: 69.99,
  discount: 57,
};

export const PDF_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID = 'sm_bt_boost_engagement_otp';
export const PDF_NEW_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID = 'sm_bt_x_bg_course_otp';
export const PDF_COURSE_SUBSCRIPTION_PLAN_IDS = [
  PDF_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID,
  'sm_bt_x_bg_ultimate_pack_otp',
  'sm_bt_x_bg_course_otp',
  'sm_bt_x_bg_course_sale_otp',
  'sm_bt_pr_boost_engagement_otp',
  'sm_bt_ss_boost_engagement_otp',
  'sm_bt_ps_boost_engagement_otp',
  'sm_bt_rp_boost_engagement_otp',
  'sm_bt_fsr_boost_engagement_otp',
  'sm_bt_foe_boost_engagement_otp',
  'sm_bt_frr_boost_engagement_otp',
  'sm_bt_pr_course_sale_otp',
  'sm_bt_ss_course_sale_otp',
  'sm_bt_ps_course_sale_otp',
  'sm_bt_rp_course_sale_otp',
  'sm_bt_fsr_course_sale_otp',
  'sm_bt_foe_course_sale_otp',
  'sm_bt_frr_course_sale_otp',
  'sm_bt_pr_ultimate_pack_otp',
  'sm_bt_ss_ultimate_pack_otp',
  'sm_bt_ps_ultimate_pack_otp',
  'sm_bt_rp_ultimate_pack_otp',
  'sm_bt_fsr_ultimate_pack_otp',
  'sm_bt_foe_ultimate_pack_otp',
  'sm_bt_frr_ultimate_pack_otp',
  "sm_bt_foe_ultimate_pack_sale_otp",
  "sm_bt_frr_ultimate_pack_sale_otp",
  "sm_bt_fsr_ultimate_pack_sale_otp",
  "sm_bt_pr_ultimate_pack_sale_otp",
  "sm_bt_ps_ultimate_pack_sale_otp",
  "sm_bt_rp_ultimate_pack_sale_otp",
  "sm_bt_ss_ultimate_pack_sale_otp",
  "sm_bt_x_bg_ultimate_pack_sale_otp",
  "sm_bt_foe_course_sale2_otp",
  "sm_bt_frr_course_sale2_otp",
  "sm_bt_fsr_course_sale2_otp",
  "sm_bt_pr_course_sale2_otp",
  "sm_bt_ps_course_sale2_otp",
  "sm_bt_rp_course_sale2_otp",
  "sm_bt_ss_course_sale2_otp",
  "sm_bt_x_bg_course_sale2_otp",
  "sm_bt_sg_ultimate_pack_otp",
  "sm_bt_sg_ultimate_pack_sale_otp",
  "sm_bt_sg_course_otp",
  "sm_bt_sg_course_sale_otp",
  "sm_bt_sg_course_sale2_otp"
];

export const PROFILE_REPORT_PRICE = {
  sum: 28.99,
  fullPrice: 69.99,
  discount: 57,
};
export const PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID = 'sm_bt_pdf_report';
export const PROFILE_NEW_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID = 'sm_bt_x_bg_pdf_report_otp';
export const PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_IDS = [
  PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID,
  'sm_bt_x_bg_pdf_report_otp',
  'sm_bt_x_bg_report_sale_otp',
  'sm_bt_x_bg_ultimate_pack_otp',
  'sm_bt_pr_pdf_report',
  'sm_bt_ss_pdf_report',
  'sm_bt_ps_pdf_report',
  'sm_bt_rp_pdf_report',
  'sm_bt_fsr_pdf_report',
  'sm_bt_foe_pdf_report',
  'sm_bt_frr_pdf_report',
  'sm_bt_pr_ultimate_pack_otp',
  'sm_bt_ss_ultimate_pack_otp',
  'sm_bt_ps_ultimate_pack_otp',
  'sm_bt_rp_ultimate_pack_otp',
  'sm_bt_fsr_ultimate_pack_otp',
  'sm_bt_foe_ultimate_pack_otp',
  'sm_bt_frr_ultimate_pack_otp',
  'sm_bt_pr_report_sale_otp',
  'sm_bt_ss_report_sale_otp',
  'sm_bt_ps_report_sale_otp',
  'sm_bt_rp_report_sale_otp',
  'sm_bt_fsr_report_sale_otp',
  'sm_bt_foe_report_sale_otp',
  'sm_bt_frr_report_sale_otp',
  "sm_bt_foe_ultimate_pack_sale_otp",
  "sm_bt_frr_ultimate_pack_sale_otp",
  "sm_bt_fsr_ultimate_pack_sale_otp",
  "sm_bt_pr_ultimate_pack_sale_otp",
  "sm_bt_ps_ultimate_pack_sale_otp",
  "sm_bt_rp_ultimate_pack_sale_otp",
  "sm_bt_ss_ultimate_pack_sale_otp",
  "sm_bt_x_bg_ultimate_pack_sale_otp",
  "sm_bt_foe_report_sale2_otp",
  "sm_bt_frr_report_sale2_otp",
  "sm_bt_fsr_report_sale2_otp",
  "sm_bt_pr_report_sale2_otp",
  "sm_bt_ps_report_sale2_otp",
  "sm_bt_rp_report_sale2_otp",
  "sm_bt_ss_report_sale2_otp",
  "sm_bt_x_bg_report_sale2_otp",
  "sm_bt_sg_ultimate_pack_otp",
  "sm_bt_sg_ultimate_pack_sale_otp",
  "sm_bt_sg_pdf_report_otp",
  "sm_bt_sg_report_sale_otp",
  "sm_bt_sg_report_sale2_otp"
];

export enum AppRoutes {
  SIGN_IN = '/sign-in',
  DASHBOARD = '/dashboard',
  INSIGHTS = '/dashboard/insights',
  NEWS = '/dashboard/news',
  NEWS_POST = '/dashboard/news/:slug',
  ANALYTICS = '/dashboard/analytics',
  PDF_COURSE = '/dashboard/course',
  PASSWORD_SAFETY = '/dashboard/password-safety',
  HOME = '*',
  PRIVACY = '/privacy',
  TERMS = '/terms-of-use',
  ACCESS_RECOVERY = '/access-recovery',
  PASSWORD_RECOVERY = '/password-recovery',
  CANCEL_SUBSCRIPTION = '/unsubscription',
  ARTICLES = '/dashboard/articles',
  ARTICLE = '/dashboard/articles'
}

export const FETCH_STATUSES: ConstantMap<FetchStatus> = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const mediaQueries = {
  s: '(max-width: 600px)',
  m: '(max-width: 800px)',
  l: '(max-width: 1024px)',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const STORAGE_USERNAME_KEY = 'igusername';


// 5 hours in milliseconds
export const LOGOUT_TIMEOUT = 5 * 60 * 60 * 1000;

// 10 hours in milliseconds
export const FETCHING_TIMEOUT = 10 * 60 * 60 * 1000;
