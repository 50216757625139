import React, { useEffect, useState } from 'react';
import * as S from './UsernamePopover.styles';

import prefixEmailIcon from '../../assets/svg/prefix-email.svg';
import checkMarkIcon from '../../assets/svg/checkmark.svg';
import igIcon from '../../assets/svg/ig-icon.svg';

interface PopOverProps {
  onSubmit: (value: string) => void;
  onSuccess: () => void;
  onClose: () => void;
  screen: 'form' | 'loading' | 'success';
  error?: string;
  duration: number;
}

const useProgressBar = (durationInSeconds: number): number => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (durationInSeconds <= 0) return;

    const totalDuration = durationInSeconds * 1000;
    const interval = 100;
    const step = 100 / (totalDuration / interval);

    const timer = setInterval(() => {
      setProgress((prev) => {
        const nextValue = prev + step;

        return nextValue >= 100 ? 100 : nextValue;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [durationInSeconds]);

  return +progress.toFixed(0);
};

const Loader = ({ duration }: { duration: number }) => {
  const progress = useProgressBar(duration);

  return (
    <S.LoaderWrapper>
      <S.Loader></S.Loader>
      <S.Progress>{progress}%</S.Progress>
    </S.LoaderWrapper>
  );
};

const UsernamePopover: React.FC<PopOverProps> = ({
  onSubmit,
  onClose,
  screen,
  error,
  duration,
}) => {
  const [username, setUsername] = useState('');
  const [invalid, setInvalid] = useState(false);

  const handleSubmit = () => {
    if (!username.trim()) {
      setInvalid(true);
    } else {
      setInvalid(false);
      onSubmit(username.trim());
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <S.Backdrop onClick={onClose}>
      <S.Container onClick={(e) => e.stopPropagation()}>
        {screen === 'form' ? (
          <S.Body>
            <S.Badge>
              <img src={igIcon} alt="checkmark" />
            </S.Badge>
            <S.Heading>Instagram account </S.Heading>
            <S.Description>
              Please enter your Instagram username to get your personalized
              profile report
            </S.Description>
            <S.InputContainer>
              <S.InputLabel>Instagram username</S.InputLabel>
              <S.InputWrapper>
                <img src={prefixEmailIcon} alt="" />
                <S.Input
                  autoCapitalize='none'
                  autoCorrect='off'
                  autoComplete='off'
                  invalid={invalid}
                  type="text"
                  placeholder='"Sherlock" for example'
                  value={username}
                  onChange={(e) => setUsername(e.target.value.toLowerCase())}
                />
              </S.InputWrapper>
              {invalid && <S.Error>Fill in the field</S.Error>}
              {error && !invalid && <S.Error>{error}</S.Error>}
            </S.InputContainer>
            <S.SubmitButton onClick={handleSubmit}>Continue</S.SubmitButton>
          </S.Body>
        ) : screen === 'loading' ? (
          <S.Body>
            <Loader duration={duration} />
            <S.Heading style={{ margin: '20px 0 0' }}>
              Analyzing your account
            </S.Heading>
            <S.Description style={{ margin: '10px 0 0', color: '#232631' }}>
              We're reviewing your Instagram account.{'\n'}
              <b>Please keep this window open until the process is finished.</b>
            </S.Description>
          </S.Body>
        ) : screen === 'success' ? (
          <S.Body>
            <S.Badge>
              <img src={checkMarkIcon} alt="checkmark" />
            </S.Badge>
            <S.Heading>Perfect!</S.Heading>
            <S.Description style={{ margin: '10px 0 0' }}>
              Your personalized report will be sent to your email as soon as it
              is ready!
            </S.Description>
            <S.SubmitButton onClick={onClose}>Got it</S.SubmitButton>
          </S.Body>
        ) : null}
      </S.Container>
    </S.Backdrop>
  );
};

export default UsernamePopover;
