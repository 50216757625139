import { createTheme, ThemeOptions, ThemeProvider, Theme } from '@mui/material';

import { CSSProperties, ReactNode } from 'react';

import {
  Typography,
  TypographyOptions,
} from '@mui/material/styles/createTypography';
import { PaletteOptions } from '@mui/material/styles/createPalette';

interface CustomTypographyOptions extends TypographyOptions {
  sTitleL: CSSProperties;
  sTitleN: CSSProperties;
  sTitleM: CSSProperties;
  sTitleMS: CSSProperties;
  sTitleS: CSSProperties;
  sSubTitle: CSSProperties;
  sSectionTitle: CSSProperties;
  sTitleXSM: CSSProperties;
}

interface CustomCommonColors {
  blue: string;
  lightBlue: string;
  lightBlue10: string;
  lightGrey: string,
  green: string;
  red: string;
}

interface CustomPaletteOptions extends PaletteOptions {
  common: CustomCommonColors & PaletteOptions['common'];
}

declare module '@mui/material/styles' {
  interface CustomTheme extends Theme {
    typography: CustomTypographyOptions & Typography;
  }

  interface CustomThemeOptions extends ThemeOptions {
    typography?: CustomTypographyOptions & ThemeOptions['typography'];
    palette?: CustomPaletteOptions;
  }

  export function createTheme(options?: CustomThemeOptions): CustomTheme;

  interface TypographyVariants {
    sTitleL: CSSProperties;
    sTitleN: CSSProperties;
    sTitleM: CSSProperties;
    sTitleMS: CSSProperties;
    sTitleS: CSSProperties;
    sSubTitle: CSSProperties;
    sTitleXS: CSSProperties;
    sSectionTitle: CSSProperties;
    sTitleXSM: CSSProperties;
  }

  interface CommonColors extends CustomCommonColors {}

  interface TypographyVariantsOptions {
    sTitleL?: CSSProperties;
    sTitleN?: CSSProperties;
    sTitleM?: CSSProperties;
    sTitleMS?: CSSProperties;
    sTitleS?: CSSProperties;
    sSubTitle?: CSSProperties;
    sTitleXS?: CSSProperties;
    sSectionTitle?: CSSProperties;
    sTitleXSM?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  export interface TypographyPropsVariantOverrides {
    sTitleL: true;
    sTitleN: true;
    sTitleM: true;
    sTitleMS: true;
    sTitleS: true;
    sSubTitle: true;
    sTitleXS: true;
    sSectionTitle: true;
    sTitleXSM: true;
  }
}

const theme = createTheme({
  palette: {
    grey: {
      100: '#EBEBEB',
      200: '#8C8E96',
      300: '#F5F5F5',
      400: '#EAEAEA',
    },
    text: {
      primary: '#232631',
      secondary: '#8C8E96',
    },
    common: {
      blue: '#256BFD',
      lightBlue: 'rgba(37,107,253, 0.30)',
      lightBlue10: 'rgba(37,107,253, 0.10)',
      lightGrey:'rgba(35, 38, 49, 0.1)',
      green: '#56AC14',
      red: '#FF435A',
    },
  },
  typography: {
    fontFamily: [
      'Avenir Next',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    sSectionTitle: {
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '44px',
    },
    sTitleL: {
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: '66px',
    },
    sTitleN: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '33px',
    },
    sTitleM: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '28px',
    },
    sTitleMS: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '28px',
    },
    sTitleS: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '33px',
    },
    sTitleXS: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    sTitleXSM: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    sSubTitle: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@media (max-width: 800px)': {
            typography: {
              sTitleL: {
                fontSize: '40px',
                lineHeight: '55px',
              },
              sTitleS: {
                fontSize: '20px',
                lineHeight: '28px',
              },
              sSectionTitle: {
                fontSize: '28px',
                lineHeight: '38px',
              },
            },
          },
        },
      },
    },
  },
});

function ThemeContext({ children }: { children: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default ThemeContext;
