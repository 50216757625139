import PageLayout from '../../components/PageLayout';
import { Box, Typography, useTheme } from '@mui/material';
import Bulb2 from '../../../../assets/images/dashboard/bulb-2.webp';
import Bell from '../../../../assets/images/dashboard/bell.webp';
import Divider from '../../components/Divider';
import NavigationBlock from '../../components/NavigationBlock';
import { AppRoutes } from '../../../../utils/constants';
import { transformInsightsItem } from './Insights.helpers';

import { RecommendationItem, StepRecommendationItem } from './components';
import useScrollTop from '../../../../hooks/useScrollTop';

export default function Insights() {
  useScrollTop();

  const theme = useTheme();

  const sectionTitleStyles = {
    marginTop: '40px',
    fontFamily: 'Avenir Next',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32.78px',
    textAlign: 'left',
    marginBottom: '20px',
    color: theme.palette.text.primary,
  };

  return (
    <PageLayout title="Tips & Insights">
      <Box
        sx={{
          padding: '30px 20px 0',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            '& > img': {
              position: 'absolute',
              top: '-65%',
              right: '-20px',
              width: '120px',
              height: '290px',
              pointerEvents: 'none',
            },
          }}
        >
          <Typography
            sx={{
              maxWidth: '233px',
              fontFamily: 'Avenir Next',
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '32.78px',
              textAlign: 'left',
              color: theme.palette.text.primary,
              '& > span': {
                color: '#256BFD',
              },
            }}
          >
            We have prepared some tips to help{' '}
            <span>improve the performance</span> of your Instagram account.
          </Typography>
          <img src={Bulb2} alt="bulb-2" />
        </Box>
        <Divider margin="40px 0 0" />
        <Typography sx={sectionTitleStyles}>
          Recommendations for Increasing Engagement
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {[
            {
              title: 'Post More Frequently',
              description:
                'Increase the frequency of posts to keep followers engaged',
            },
            {
              title: 'Engage with Followers',
              description:
                'Respond to comments and messages to build a community feel',
            },
            {
              title: 'Use Stories and Reels',
              description:
                'Leverage Instagram Stories and Reels to provide varied content and reach a broader audience',
            },
            {
              title: 'Incorporate Hashtags',
              description:
                'Use relevant and trending hashtags to increase post visibility',
            },
            {
              title: 'Host Giveaways',
              description:
                'Encourage followers to engage with content through giveaways and contests',
            },
            {
              title: 'Collaborate with Influencers',
              description:
                'Partner with influencers in your niche to gain exposure to their followers',
            },
          ].map((item, index) => (
            <RecommendationItem key={item.title} {...item} />
          ))}
        </Box>
        <Typography sx={sectionTitleStyles}>
          Recommendations for Increasing Followers
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '0',
          }}
        >
          {[
            {
              title: 'Optimize Profile',
              description:
                'Ensure the profile is visually appealing and provides clear information about the account',
            },
            {
              title: 'Cross-Promote on Other Platforms',
              description:
                'Promote your Instagram account on other social media platforms like Facebook, Twitter, and TikTok',
            },
            {
              title: 'Content Quality',
              description:
                'Post high-quality, visually appealing photos and videos',
            },
            {
              title: 'Engage with Other Accounts',
              description:
                'Like, comment, and follow other accounts in your niche to attract their followers',
            },
            {
              title: 'Run Instagram Ads',
              description:
                'Use Instagram ads to target potential followers based on demographics and interests',
            },
            {
              title: 'Post at Optimal Times',
              description:
                'Analyze when your audience is most active and schedule posts accordingly',
            },
          ].map((item, index) => (
            <RecommendationItem key={item.title} {...item} />
          ))}
        </Box>

        <Typography sx={{ ...sectionTitleStyles, marginTop: '60px' }}>
          To summarize all of the above, we can formulate the following basic
          recommendations:{' '}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {[
            {
              title: 'Content Optimization',
              description:
                'Create content that aligns more with the interests of your audience. Experiment with different types of content (photos, videos, Reels) to see what garners more engagement.',
            },
            {
              title: 'Increase Interactivity',
              description:
                'Use interactive features like polls, questions, and quizzes in Instagram Stories to increase engagement.',
            },
            {
              title: 'Leverage Top Commenters',
              description:
                'Engage more with top commenters by acknowledging their comments and perhaps featuring their content. This can help build a stronger community feel.',
            },
            {
              title: 'Post Timing Strategy',
              description:
                'Identify the times when posts receive the most engagement and schedule future posts around those times to maximize reach.',
            },
            {
              title: 'Enhance Visual Appeal',
              description:
                'Invest in higher quality visuals and consider a consistent theme or aesthetic for the profile to attract more followers',
            },
          ].map((item, index) => (
            <StepRecommendationItem
              key={item.title}
              {...transformInsightsItem(item, index)}
            />
          ))}
        </Box>
        <Divider margin="30px 0 40px" />
        <NavigationBlock
          image={Bell}
          title="Keep up to date with the latest news in the field of promotion of Instagram accounts and learn new things"
          buttonText="Read news"
          path={AppRoutes.NEWS}
        />
      </Box>
    </PageLayout>
  );
}
