import { Box } from '@mui/material';
import { APP_CONSTANTS } from '../utils/constants';
import useScrollTop from '../hooks/useScrollTop';
import { useAppState } from "../providers/AppProvider.hooks";

function TermsOfUse() {
  useScrollTop();
  const { isBoostgram } = useAppState();
  const supportEmail = isBoostgram ? APP_CONSTANTS.SUPPORT_NEW : APP_CONSTANTS.SUPPORT;

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          margin: '20px',
        }}
      >
        <div>
          <h1
            dir="ltr"
            style={{
              lineHeight: '1.38',
              textAlign: 'center',
              marginTop: '24pt',
              marginBottom: '6pt',
            }}
          >
            <span
              style={{
                fontSize: '34.5pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              END USER LICENSE AGREEMENT
            </span>
          </h1>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.38',
              textAlign: 'center',
              marginTop: '15pt',
              marginBottom: '15pt',
            }}
          >
            <span
              style={{
                fontSize: '15pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 700,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Last Updated: Mar 29, 2024
            </span>
          </p>
          <ul
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '12pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  This End User License Agreement is a valid agreement between
                  You and the Us. Please, read this EULA carefully. The rules of
                  the EULA are binding upon You. If You do not agree to be bound
                  by the rules of the EULA, please, do not install the
                  Application or delete it from the Device, if You have already
                  installed it. If You continue to use the Application, this
                  will mean that You have read and understood the EULA and agree
                  to be bound by it.
                </span>
                <span
                  style={{
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  DEFINITIONS AND TERMS
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  In order to understand the rules of the EULA clearly you shall
                  familiarize yourself with the meaning of terms used in the
                  EULA. In this EULA the following terms, when capitalized,
                  shall have the following meanings:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Account” – means a personal account of a user of the
                      Platform services, which is provided by the Platform
                      Administrator under the Platform Terms of Use or similar
                      document. The Account is accessed with a Platform ID
                      (account name) and a password.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “App Store” – means a virtual store (platform) managed by
                      Apple for distribution of applications for devices running
                      iOS operating system.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Apple” – means Apple, Inc., a company incorporated in
                      California, the United States of America, any parent or
                      group company or any successor thereof that manages App
                      Store.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Application”, “App” – means an application for a mobile
                      device that We make available on App Store, as well as any
                      services provided through the Application.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “We”, “Us” – mean ROSIANE DA ROSA ANDRADES, Rua Nove 1424,
                      Jaboatão Dos Guararapes, Pernambuco 54240-080, Brazil.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Content” – means all kinds of content, including, among
                      other, data, texts, images, videos, sounds, links,
                      software and other pieces of information in any form,
                      which are made available to You through the App.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Device” – means a smartphone, a tablet or another similar
                      mobile device that You either own or otherwise legally
                      control exclusively for Your personal non-commercial use.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “EULA” – means this End User License Agreement, which is a
                      binding agreement between You and Us. The EULA may
                      incorporate other documents, such as Privacy Policy that
                      regulate relations between You and Us with regard to using
                      the Application. If a document represents a part of the
                      EULA, it has a clear indication thereof.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Google” – means Google Inc, registered in Delaware, USA
                      with the headquarters located at the address: 1600
                      Amphitheatre Parkway, Mountain View, CA 94043, USA; Google
                      Ireland Limited registered in Ireland with the
                      headquarters located at the address: Gordon House, Barrow
                      Street, Dublin 4, Ireland; Google Commerce Limited
                      registered in Ireland with the headquarters located at the
                      address: Gordon House, Barrow Street, Dublin 4; as well as
                      Google Asia Pacific Pte. Limited registered in Singapore
                      with the headquarters located at the address: 8 Marina
                      View, Asia Square 1 #30-01, Singapore 018960.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Google Play” – means a virtual store (platform) managed
                      by Google for distribution of applications for devices
                      running Android operating system.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Intellectual Property Rights” – means, collectively,
                      rights under any patent, trademark, copyright and trade
                      secret laws, and any other intellectual property or
                      proprietary rights recognized in any country or
                      jurisdiction worldwide, including, without limitation,
                      moral or similar rights.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Platform” – Google Play (for users who use the
                      Application on a Device running Android operating system)
                      or App Store (for users who use the Application on a
                      Device running iOS operating system).
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Platform Administrator” – Google (for Google Play virtual
                      store) and Apple (for App Store virtual store).
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “Subscription” – means a period, during which You use the
                      App as a whole or certain additional Content for a fee.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “User-generated Content” – means any form of Content,
                      including, among other, data, texts, images, videos,
                      sounds, links, that have been posted by users to online
                      platforms, for example, social networks.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      “You” – means an individual using the App.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  LEGAL CAPACITY AND AGE
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The Application is designed for users over 16. If You
                      install and/or use the App, You confirm that You are at
                      least 16 years old and have full legal capacity to enter
                      into the Agreement.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If You are between 16 and 18 years old, Your parent,
                      guardian or other representative of legal age has to check
                      and agree to this EULA on Your behalf. If You install
                      and/or use the App, You confirm that Your parent, guardian
                      or other representative of legal age has agreed to the
                      terms of this EULA and permits You to use the App.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  CHANGES OF THE EULA
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We may introduce changes to this EULA from time to time.
                      You shall regularly check the latest version of the EULA
                      at the link, available on the web-page of the App on the
                      Platform.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If You do not agree with the terms of the updated EULA,
                      You are not allowed to use the Application starting from
                      the day, on which the updated EULA takes effect. In this
                      case You shall delete the App from all Your Devices and
                      cancel renewal of your Subscriptions.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  DEVICE
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If You intend to download onto and/or run the App on any
                      Device not owned by You, You must have the owner’s
                      permission to do so. You will be responsible for complying
                      with this EULA whether or not You own the Device.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If You do not own the Device, You are allowed to install
                      and/or use the App only through Your Account.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If You use the Device together with other people, please,
                      make sure that nobody except You can use the Application
                      from Your Account. All actions taken in Your Account are
                      considered Your actions in disregard of who actually takes
                      such actions. You are fully responsible for all actions
                      taken in Your Account.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If You decide to sell or otherwise dispose of the Device,
                      You shall delete the Application from the Device.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  INTELLECTUAL PROPERTY
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We hereby grant You a non-exclusive, personal, limited,
                      revocable and non-transferable license to access, download
                      and run the App only on Your Device, provided that You
                      agree to comply with all the terms and conditions of this
                      EULA. Please, take into consideration that the App is
                      licensed, not sold, to You.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We or the licensor of Us is the only holder of
                      Intellectual Property Rights with regard to the
                      Application. We retain all rights, title and interest in
                      and to the App and reserves all rights not expressly
                      granted to You in this EULA.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      You may not sell, resell, distribute, redistribute, rent,
                      lease, sublicense, assign, copy, or in any other way
                      transfer or grant any rights to the App to any third
                      party.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      You may not distribute or make the App available over any
                      network where it could be downloaded or used by multiple
                      users at the same time, except when it is expressly
                      authorized by Us.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      You may not use any automatic or manual device or process
                      to interfere with the proper working of the App. However,
                      You may use any kind of device or process to delete the
                      App from Your Device.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      You may not decompile, disassemble, reverse engineer or
                      create derivative works of the App or any parts thereof.
                      All modifications or enhancements to the App remain the
                      sole property of Us.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      You may not use the App in any unlawful manner, for any
                      unlawful purpose, or in any manner inconsistent with this
                      EULA or act fraudulently or maliciously, for example, by
                      hacking into or inserting malicious code, such as viruses,
                      or harmful data, into the App.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  UPDATES
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We may, though not obliged, update the App from time to
                      time, making such updates, supplements or new versions of
                      the App available to You. If We release an update
                      (including a new version of the App) We may request You to
                      update the App. The update may be downloaded and installed
                      automatically or on the basis of Your consent depending on
                      the updates settings of Your Device.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The content of any update, supplement or new version of
                      the App is determined exclusively by Us. Under no
                      circumstances do We accept any orders, requires or
                      requests for further development of the App. We reserve
                      the right to modify or discontinue any part, or function,
                      or feature of the App at Our own discretion with or
                      without a prior notice.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If We introduce certain features or functions of the App,
                      We may need to set certain rules for using such features
                      or functions. In this case We will introduce changes to
                      the EULA that will take effect on the day when such
                      features or functions are released.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  PURCHASING SUBSCRIPTION. TRIAL PERIOD
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The App as a whole or its certain part (additional
                      Content) is provided on a paid subscription basis. The fee
                      may vary depending on the App, the period of the
                      Subscription duration.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Purchase from the App Store or Google Play.
                    </span>
                  </p>
                  <ul
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingInlineStart: '48px',
                    }}
                  >
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          When You purchase a Subscription from the App Store or
                          Google Play, the Platform Administrator charges Your
                          Account and collects money on Our behalf. The payment
                          is processed by a payment processing system
                          implemented by the Platform Administrator.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Please, kindly notice that We neither process Your
                          payment nor have any access to Your payment
                          information, such as, among other, Your bank card
                          data, Your name or any other related information.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Should You have any question with regard to payment
                          processing, please, submit Your issue to user support
                          service of the Platform Administrator.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Your Account is charged with the cost of the new
                          Subscription within 24 hours prior the end of the
                          current Subscription. If You cancel automatic renewal
                          within the last 24 hours of the Subscription, such
                          cancellation will take effect at the end of the new
                          Subscription.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Please, kindly notice that We do not make refunds. The
                          digital content is made available to You immediately
                          after the Subscription fee is charged to Your Account.
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Direct Purchase from the Company.
                    </span>
                  </p>
                  <ul
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingInlineStart: '48px',
                    }}
                  >
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          You may purchase a Subscription directly from the
                          Company, in which case it is the Company and the
                          external payment service providers (Stripe, PayPal
                          etc.), that collects money.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          The costs of all Subscriptions along with this EULA as
                          well as Privacy Policy are available for your
                          attention on the Website and web-page of the App
                          before the purchase.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          When You purchase a Subscription, the payment is
                          processed by a payment processing system you use.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          The digital content is made available to You
                          immediately after the trial period begins or after the
                          Subscription fee is charged.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Please kindly note that we offer refunds in case of
                          Direct Purchase from the Company (please see clause
                          8).
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Trial periods may be provided depending on the
                          particular Subscription and the App you choose. You
                          may use the App or its additional Content free of
                          charge during this period. Please, take into
                          consideration that at the end of the trial period it
                          will automatically transform into a paid Subscription
                          in accordance with clause 6 and Your Account will be
                          charged for the cost of such Subscription, unless You
                          cancel the Subscription through the subscription
                          settings of Your Account at least 24 hours prior to
                          the end of the free trial period.
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      AUTOMATIC RENEWAL OF SUBSCRIPTION. CANCELLATION OF
                      SUBSCRIPTION
                    </span>
                  </p>
                  <ul
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingInlineStart: '48px',
                    }}
                  >
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          The Subscription will renew automatically for the same
                          period at the end of the Subscription term at the cost
                          of the chosen package. Your Account will be charged
                          for the renewed Subscription within 24 hours prior to
                          the end of the current Subscription. If You cancel
                          automatic renewal within the last 24 hours of the
                          Subscription, such cancellation will take effect at
                          the end of the new Subscription.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          You may cancel renewal of the Subscription at least 24
                          hours before the end of the Subscription: in this case
                          the App as a whole or its additional Content will be
                          available to You until the end of the Subscription.
                          After that the Subscription will not be renewed
                          automatically.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          In order to cancel automatic renewal of the
                          Subscription, please, change subscription settings of
                          Your Account. After the end of the Subscription the
                          App as a whole or its additional Content will be
                          available only if a new Subscription is purchased.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          If You uninstall the App, Your Subscription will not
                          stop automatically. You must cancel automatic renewal
                          of Your Subscription in order not to be charged with
                          the cost of the new Subscription.
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  REFUND POLICY
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      MONEY-BACK GUARANTEE RULES
                    </span>
                  </p>
                  <ul
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingInlineStart: '48px',
                    }}
                  >
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Please kindly note that we offer refunds to our
                          customers who made direct purchase from the Company.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          To request a refund please e-mail a written
                          application to&nbsp;
                        </span>
                        <a
                          href={`mailto:${supportEmail}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <span
                            style={{
                              fontSize: '11pt',
                              fontFamily: 'Arial,sans-serif',
                              color: '#1155cc',
                              backgroundColor: 'transparent',
                              fontWeight: 400,
                              fontStyle: 'normal',
                              fontVariant: 'normal',
                              textDecoration: 'underline',
                              WebkitTextDecorationSkip: 'none',
                              textDecorationSkipInk: 'none',
                              verticalAlign: 'baseline',
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {supportEmail}
                          </span>
                        </a>
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          &nbsp;containing the following information:
                        </span>
                      </p>
                      <ul
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                          paddingInlineStart: '48px',
                        }}
                      >
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: 'none',
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                          }}
                          aria-level={4}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: '1.38',
                              marginTop: '0pt',
                              marginBottom: '0pt',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '11pt',
                                fontFamily: 'Arial,sans-serif',
                                color: '#000000',
                                backgroundColor: 'transparent',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                fontVariant: 'normal',
                                textDecoration: 'none',
                                verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              Name and Surname of the customer, who purchased
                              the services;
                            </span>
                          </p>
                        </li>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: 'none',
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                          }}
                          aria-level={4}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: '1.38',
                              marginTop: '0pt',
                              marginBottom: '0pt',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '11pt',
                                fontFamily: 'Arial,sans-serif',
                                color: '#000000',
                                backgroundColor: 'transparent',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                fontVariant: 'normal',
                                textDecoration: 'none',
                                verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              &nbsp;Payment method (PayPal, Stripe, Recurly,
                              Debit Card, etc. used to perform a purchase);
                            </span>
                          </p>
                        </li>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: 'none',
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                          }}
                          aria-level={4}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: '1.38',
                              marginTop: '0pt',
                              marginBottom: '0pt',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '11pt',
                                fontFamily: 'Arial,sans-serif',
                                color: '#000000',
                                backgroundColor: 'transparent',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                fontVariant: 'normal',
                                textDecoration: 'none',
                                verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              Date of the payment;
                            </span>
                          </p>
                        </li>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: 'none',
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                          }}
                          aria-level={4}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: '1.38',
                              marginTop: '0pt',
                              marginBottom: '0pt',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '11pt',
                                fontFamily: 'Arial,sans-serif',
                                color: '#000000',
                                backgroundColor: 'transparent',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                fontVariant: 'normal',
                                textDecoration: 'none',
                                verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              Payment receipt or any payment confirmation;
                            </span>
                          </p>
                        </li>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: 'none',
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre',
                          }}
                          aria-level={4}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: '1.38',
                              marginTop: '0pt',
                              marginBottom: '0pt',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '11pt',
                                fontFamily: 'Arial,sans-serif',
                                color: '#000000',
                                backgroundColor: 'transparent',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                fontVariant: 'normal',
                                textDecoration: 'none',
                                verticalAlign: 'baseline',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              Brief justification of the reason for the need for
                              a refund.
                            </span>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          PLEASE BE INFORMED THAT YOUR REFUND REQUEST SHALL
                          CONTAIN ALL THE INFORMATION DESCRIBED BY THE PRESENT
                          CLAUSE 8.1.2 A-E IN ORDER TO ALLOW US TO PERFORM A
                          REFUND. THE LACK OF INFORMATION FROM YOUR SIDE IN YOUR
                          REQUEST WILL LEAD TO A LACK OF TECHNICAL ABILITY TO
                          MAKE A REFUND PAYMENT. IN CASE YOUR REFUND REQUEST
                          DOES NOT CONTAIN ALL THE INFORMATION REQUIRED BY THE
                          PRESENT CLAUSE WE WILL BE UNABLE TO PERFORM A
                          REFUND.We will review your application and notify you
                          (by email) whether your application is approved. If
                          the application is approved, your refund will be
                          processed, and a credit will be automatically applied
                          to your credit card or original method of payment,
                          within 14 days.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          In order to cancel automatic renewal of the
                          Subscription, please, change subscription settings of
                          Your Account. After the end of the Subscription the
                          App as a whole or its additional Content will be
                          available only if a new Subscription is purchased.
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      IMPORTANT STATEMENT
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Please note that only fulfillment of the above
                      requirements allows you to receive a complete refund. For
                      the sake of clarity, this Refund Policy does not apply to
                      any other instances.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  USER-GENERATED CONTENT
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Some of Our Apps provide You with an opportunity to track
                      analytical data with regard to Your social network
                      profile. Functionality of such Apps make it possible to
                      view not only Your posts published in a social network,
                      but also posts of other users of this social network, if
                      such users are somehow connected to You (for example, they
                      “follow” You). Such posts, whether published by You or by
                      any other person, are considered User-generated Content.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      As far as You may access posts of other users, You may be
                      exposed to potentially objectionable User-generated
                      Content. Please, take into account that We neither control
                      nor pre-checks any User-generated Content posted on any
                      social network.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Any kind of modifications or censorship by Us with regard
                      to the User-generated Content may be considered violation
                      of human rights or copyright and may potentially mislead
                      You.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If You have been exposed to any kind of objectionable
                      content, You may use functionality of the respective
                      social network to track and delete objectionable
                      User-generated Content.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Under no circumstances shall We be responsible for any
                      User-generated Content.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  LINKS TO EXTERNAL SITES AND SERVICES
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The App may contain links to external sites and services
                      (for example, applications for mobile devices). If You
                      decide to visit and/or use such external sites and
                      services, You do this at Your own risk. Under no
                      circumstances shall We be responsible for the content of
                      such sites and services. We cannot guarantee that these
                      sites and services will be available for You or that Your
                      use of such sites and services will be safe.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      This EULA does not regulate Your relations with owners
                      and/or administrators of such external sites and services.
                      Please, check an end user license agreement, terms of
                      service, privacy policy or any other related document that
                      regulates usage of external sites and services.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  DISCLAIMER
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      To the fullest extent permitted by applicable law, We and
                      Our affiliates, officers, employees, agents, partners, and
                      licensors expressly disclaim all warranties of any kind,
                      express or implied, as to the accuracy, adequacy or
                      completeness of any of the information contained in Our
                      Apps or created by the services.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The Content in Our Apps is provided to You on an “as is”
                      basis, without any warranties as to merchantability or
                      fitness for a particular purpose or use. We do not
                      guarantee that You will attain any anticipated result from
                      the use of the Apps.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We make no warranty that:
                    </span>
                  </p>
                  <ul
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingInlineStart: '48px',
                    }}
                  >
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          the App will meet Your requirements;
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          the App will be error-free, secure, or uninterrupted;
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          any App functionality errors will be corrected;
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          the quality of the App will meet Your expectations.
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Any service downloaded or otherwise accessed through the
                      use of Our Apps is at Your own discretion and risk and We
                      shall have no responsibility or liability for any errors
                      or omissions. We are not responsible for any damages,
                      whether direct or indirect, special or consequential,
                      suffered by You or by any other person from the use of the
                      Apps, even if You have been advised of the possibility of
                      such damages. No advice or information, whether oral or
                      written, obtained by You from Us or Our Apps shall create
                      any warranty not expressly stated in the EULA. Under no
                      circumstances shall Our liability or of any of Our
                      affiliates pursuant to any cause of action, whether in
                      contract, tort, or otherwise, exceed the fee paid by You
                      for access to the Apps. Furthermore, We shall have no
                      responsibility or liability for delays or failures due to
                      circumstances beyond Our control.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  TRANSFER OF RIGHTS
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We may transfer Our rights and obligations under this EULA
                      to any other organization or individual without Your
                      consent. We will always tell You in writing if this
                      happens and We will ensure that the transfer will not
                      affect Your rights under the EULA.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      You may only transfer Your rights or Your obligations
                      under this EULA to another person if We agree in writing.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  YOUR VIOLATION OF THE EULA
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      We may end Your rights to use the App at any time by
                      contacting You if You have broken the rules of this EULA
                      in a serious way. If what You have done can be put right
                      We will give You a reasonable opportunity to do so.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If We end Your rights to use the App:
                    </span>
                  </p>
                  <ul
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      paddingInlineStart: '48px',
                    }}
                  >
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          &nbsp;You must stop all activities authorized by this
                          EULA, including Your use of the App; and
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: 'none',
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre',
                      }}
                      aria-level={3}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: '1.38',
                          marginTop: '0pt',
                          marginBottom: '0pt',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '11pt',
                            fontFamily: 'Arial,sans-serif',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontVariant: 'normal',
                            textDecoration: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          &nbsp;You must delete or remove the App from all
                          Devices and immediately destroy all copies of the App
                          which You have and confirm to Us that You have done
                          this.
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: 'none',
                fontSize: '11pt',
                fontFamily: 'Arial,sans-serif',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre',
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: '1.38',
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  padding: '15pt 0pt 0pt 0pt',
                }}
              >
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  MISCELLANEOUS
                </span>
                <span
                  style={{
                    fontSize: '15pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <br />
                  <br />
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: '48px',
                }}
              >
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Each of the paragraphs of this EULA operates separately.
                      If any court or relevant authority decides that any of
                      them is unlawful, the remaining paragraphs will remain in
                      full force and effect.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Even if We delay enforcing this EULA, We can still enforce
                      it later. If We do not insist immediately that You do
                      anything You are required to do under this EULA terms, or
                      if We delay taking steps against You in respect of Your
                      breaking the EULA, that will not mean that You do not have
                      to do those things and it will not prevent Us taking steps
                      against You at a later date.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      This EULA shall be governed and construed in accordance
                      with the laws of New York, United States of America,
                      without regard to its conflict of law provisions.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '0pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      If any dispute, controversy or difference arises between
                      You and the Company, it shall be settled amicably through
                      negotiations. In case such dispute, controversy or
                      difference cannot be settled amicably through negotiations
                      within a thirty 30-day period it shall be settled by the
                      American Arbitration Association (the AAA) in accordance
                      with its International Expedited Procedures.
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The number of arbitrators shall be one.
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The place of arbitration shall be Florida, the United
                      States of America.
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The arbitration shall be held, and the award rendered, in
                      English.
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      The appointing authority shall be the AAA acting in
                      accordance with the rules adopted by the AAA for this
                      purpose.
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Each party (i.e. You and the Company) shall bear its own
                      expenses, but the parties shall share equally the expenses
                      of the arbitration tribunal.
                    </span>
                  </p>
                </li>
                <li
                  dir="ltr"
                  style={{
                    listStyleType: 'decimal',
                    fontSize: '11pt',
                    fontFamily: 'Arial,sans-serif',
                    color: '#000000',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    textDecoration: 'none',
                    verticalAlign: 'baseline',
                    whiteSpace: 'pre',
                  }}
                  aria-level={2}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: '1.38',
                      marginTop: '0pt',
                      marginBottom: '12pt',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '11pt',
                        fontFamily: 'Arial,sans-serif',
                        color: '#000000',
                        backgroundColor: 'transparent',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        fontVariant: 'normal',
                        textDecoration: 'none',
                        verticalAlign: 'baseline',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      Support. If You want to learn more about the App or have
                      any problems using it, please, contact Us at
                      {supportEmail}.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ul>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </div>
      </Box>
    </Box>
  );
}

export default TermsOfUse;
