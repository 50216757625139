import styled from '@emotion/styled';

export const StyledButton = styled.button`
  font-family: Avenir Next;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  padding: 17px 29px;
  border-radius: 12px;
  color: white;
  border: 0;
  background: #256bfd;

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: #1a5fc1;
  }

  transition: all 0.4s ease-in-out;
`;
