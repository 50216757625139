import { fetcher } from '../utils/fetcher';
import { Nullable } from '../models/common';

export const isBrowser: boolean = typeof window !== 'undefined';

export type UserSubscription = {
  emails: Array<string>;
  bundle: string;
  id: string;
  started_at: string;
  status: string;
  plan_code: string;
  main_plan: boolean;
};

type SubscriptionsResponse = {
  subscriptions: Array<UserSubscription>;
};

export async function getUserSubscriptions({ token }: { token: string }): Promise<SubscriptionsResponse> {
  return fetcher(process.env.REACT_APP_RECURLY_URI + '/subscriptions/' + token, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

export enum INSIGHTS_STATUS {
  IDLE = 'idle',
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export type InstagramUserData = {
  private: boolean;
  username: string;
  fullName: string;
  followersCount: number;
  followsCount: number;
  postsCount: number;
  profilePicUrl: string;
  // searchTerm: string;
  // inputUrl: string;
  // id: string;
  // url: string;
  // biography: string;
  // hasChannel: boolean;
  // highlightReelCount: number;
  // isBusinessAccount: boolean;
  // joinedRecently: boolean;
  // businessCategoryName: string;
  // verified: boolean;
  // profilePicUrlHD: string;
  // igtvVideoCount: number;
  // relatedProfiles: [];
  // latestIgtvVideos: [];
  // latestPosts: [];
  // facebookPage: {
  //   id: string;
  //   category: string;
  //   imageURI: string;
  //   likes: number;
  //   verification: string;
  //   name: string;
  //   country: string | null;
  //   entityType: string;
  //   igUsername: string;
  //   igFollowers: number;
  //   igVerification: boolean;
  //   pageAlias: string;
  //   pageIsDeleted: boolean;
  // };
  _insights: {
    status: INSIGHTS_STATUS;
    estimated_completion_date: {
      _seconds: number;
      _nanoseconds: number;
    };
    prevData: {
      insights: Array<InsightsItem>;
      recommendationsForIncreasingEngagement: Array<InsightsItem>;
      recommendationsForIncreasingFollowers: Array<InsightsItem>;
      recommendationsBasedOnInsights: Array<InsightsItem>;
    };
    lastData: {
      insights: Array<InsightsItem>;
      recommendationsForIncreasingEngagement: Array<InsightsItem>;
      recommendationsForIncreasingFollowers: Array<InsightsItem>;
      recommendationsBasedOnInsights: Array<InsightsItem>;
    };
  };
  _news: Array<NewsItem>;
  _calculatedData: {
    overviewStatistics: {
      totalPostsAnalyzed: number;
      totalLikes: number;
      totalComments: number;
    };
    userEngagement: {
      engagementRate: {
        status: string;
        value: number;
        cell: number;
      };
      averageCommentsPerPost: number;
      averageLikesPerPost: number;
    };
    topPosts: {
      mostLikedPosts: Array<TopPostItem>;
      mostCommentedPosts: Array<TopPostItem>;
    };
    audience: {
      secretFans: InstagramUserDataCategory;
      lostFollowers: InstagramUserDataCategory;
      newFollowers: InstagramUserDataCategory;
      inactiveFollowers: InstagramUserDataCategory;
      friends: InstagramUserDataCategory;
      fans: InstagramUserDataCategory;
      blockedYou: InstagramUserDataCategory;
    };
    other: {
      likes: InstagramUserDataCategory;
      comments: InstagramUserDataCategory;
    };
  };
  _updatedAt: {
    _seconds: number;
    _nanoseconds: number;
  };
};

export type InsightsItem = {
  title: string;
  description: string;
};

export type NewsItem = {
  title: string;
  text: Array<string>;
  imagePath: string;
  slug: string;
};

export type TopPostItem = {
  postPicUrl: string;
  date: string;
  user: string;
  count: number;
};

export type LessonItem = {
  title: string;
  subTitle: string;
  image: string;
  lessonPdf: string;
};

export type InstagramUserDataCategory = {
  totalValue: number | null;
  items: Array<CategoryObj | CategoryErrObj>;
};

export type CategoryObj = {
  postUrl: string;
  likesCount: number;
  ownerUsername: string;
  ownerProfilePicUrl: string;
  id: string;
  text: string;
  timestamp: string;
};

export type CategoryErrObj = {
  extensions: {
    is_final: boolean;
  };
  data: null;
  errors: [];
};

export type InstagramUserSuccessResponse = {
  statusCode: number;
  response: InstagramUserData;
};

export type InstagramUserErrorResponse = {
  statusCode: number;
  message: string;
};

export async function fetchInstagramUser({
  username,
  subscriptionId,
  uid,
  environment,
}: {
  username: string;
  subscriptionId: Nullable<string>;
  uid: Nullable<string>;
  environment: Nullable<string>;
}): Promise<InstagramUserSuccessResponse | InstagramUserErrorResponse> {
  return fetcher('https://us-central1-followerse-370015.cloudfunctions.net/ig-getPublicProfile', {
    method: 'POST',
    body: JSON.stringify({
      usernames: [username],
      subscriptionId: subscriptionId,
      uid: uid,
      environment: environment,
      // forceUpdate: true,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function sendPDFReportData({
  username,
  email,
  uid,
}: {
  username: string;
  email: string | null;
  uid: string;
}): Promise<any> {
  return fetcher('https://us-central1-followerse-370015.cloudfunctions.net/ig-getPdfReport', {
    method: 'POST',
    body: JSON.stringify({
      username,
      email,
      uid,
      hostname: window.location.hostname,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export interface SubscribeRecurlySecure3d {
  actionTokenId: string;
  code: string;
}
export interface SubscribeRecurlySuccess {
  id: string;
  uuid: string;
  account: {
    code: string;
  };
}

export interface SubscribeRecurlyError {
  error: string;
}

export function createSubscription({
  plan_id,
  email,
  firebase_id,
}: {
  plan_id: string;
  email: string;
  firebase_id: string;
}): Promise<SubscribeRecurlySuccess | SubscribeRecurlyError | SubscribeRecurlySecure3d> {
  const body = {
    plan_id,
    email,
    firebase_id,
  };

  return fetcher(window.subtrack_url + '/rec/create-subscription', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export interface CancelSubscriptionSuccess {
  success: boolean;
}
export interface CancelSubscriptionError {
  error: string;
}

export const cancelSubscription = async (
  subscriptionId: string
): Promise<CancelSubscriptionSuccess | CancelSubscriptionError> => {
  const domain = process.env.NODE_ENV === 'development' ? 'https://dev.metricgurus.com' : window.location.origin;

  return fetcher(`${domain}/api/cancel`, {
    method: 'POST',
    body: JSON.stringify({
      id: subscriptionId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

type SendActivationByEmailResponse = {
  success: boolean;
  has_user?: boolean;
};

type SendActivationByEmailError = {
  error: string;
  success: boolean;
};

export async function sendActivationByEmail({
  email,
}: {
  email: string;
}): Promise<SendActivationByEmailResponse | SendActivationByEmailError> {
  const domain = process.env.NODE_ENV === 'development' ? 'https://dev.metricgurus.com' : window.location.origin;

  const bundle = 'com.huynh.socialmetric';
  return fetch(`${domain}/api/send-activation-by-email/${bundle}/${email}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
  }).then((res) => {
    return res.json();
  });
}

export async function getUserSubscriptionState({
  recurlySubscriptionId,
}: {
  recurlySubscriptionId: string;
}): Promise<SubscriptionsResponse> {
  return fetcher(`${window.subtrack_url}/rec/subscriptions/${recurlySubscriptionId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

export async function finalizeSubscriptionRecurly(
  plan_id: string,
  subscription_id: string,
  email: string,
  firebase_id: string
): Promise<any> {
  return fetcher(`${window.subtrack_url}/rec/subscription-finalize`, {
    method: 'POST',
    body: JSON.stringify({
      plan_id,
      subscription_id,
      email,
      firebase_id,
    }),
    headers: {
      'Content-Type': 'text/plain',
    },
  });
}

type ChangePasswordResponse = {
  success: boolean;
  user_id: string;
};

type ChangePasswordError = {
  error:
    | string
    | {
        code: string;
        message: string;
      };
  success: boolean;
};

export async function changePassword({
  email,
  id,
  password,
  test,
}: {
  email: string;
  id: string;
  password: string;
  test: boolean;
}): Promise<ChangePasswordResponse | ChangePasswordError> {
  return fetch(`https://us-central1-followerse-370015.cloudfunctions.net/changePassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      id,
      password,
      test,
    }),
  }).then((res) => {
    return res.json();
  });
}

/**
 * Track appycnt events
 * @param event : string --> any event to track
 * @param params --> object with any params
 */

export async function trackEvent(event: string, params?: Record<string, any>) {
  try {
    const domain = process.env.NODE_ENV === 'development' ? 'https://dev.metricgurus.com' : window.location.origin;
    return fetcher(`${domain}/event`, {
      method: 'POST',
      body: JSON.stringify({
        userAgent: window.navigator.userAgent,
        event: event,
        ...params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export const cancelSubscriptionsRequest = async ({
  email,
  emailing,
}: {
  email: string;
  emailing: boolean;
}): Promise<any> => {
  const domain = process.env.NODE_ENV === 'development' ? 'https://dev.metricgurus.com' : window.location.origin;

  return fetcher(`${domain}/api/cancel-request`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      code: 'com.huynh.socialmetric',
      host: window.location.host,
      emailing,
    }),
    headers: {
      'Content-Type': 'text/plain',
    },
  });
};

export const cancelSubscriptionsWithParameter = async ({ content }: { content: string }): Promise<any> => {
  const domain = process.env.NODE_ENV === 'development' ? 'https://dev.metricgurus.com' : window.location.origin;

  return fetcher(`${domain}/api/cancel-subscription/${content}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
  });
};

export async function fetchNews(): Promise<{ news: Array<NewsItem> }> {
  return fetcher('https://us-central1-followerse-370015.cloudfunctions.net/ig-getNews', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export type UserBreachedSitesResponse = {
  Name: string;
  Title: string;
  Domain: string;
  BreachDate: string;
  AddedDate: string;
  ModifiedDate: string;
  PwnCount: number;
  Description: string;
  LogoPath: string;
  DataClasses: string[];
  IsVerified: boolean;
  IsFabricated: boolean;
  IsSensitive: boolean;
  IsRetired: boolean;
  IsSpamList: boolean;
  IsMalware: boolean;
  IsSubscriptionFree: boolean;
};

export async function fetchBreachedSites(account: string): Promise<Array<UserBreachedSitesResponse>> {
  return fetcher(`https://us-central1-followerse-370015.cloudfunctions.net/breachedaccount?account=${account}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

