import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/index.css';

declare global {
    interface Window {
        subtrack_url: string
        is_new_recurly: boolean
    }
}

window.subtrack_url = process.env.REACT_APP_SUBTRACK_URL ?? ''
window.is_new_recurly = false;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
